(function( $ ) {
	var
	social_toggle = $('.social-share__social-list-item--plus a'),
	search_toggle = $('.social-share__search a'),
	search_input = $('.social-share__search .search-field');
	
	if ($('body').hasClass('single')) {
		var
		like_dislikes = $('.like-dislike'),
		like_dislike_buttons = $('.like-dislike-buttons');
		
		
		like_dislike_buttons.click(function(e) {
			var clicked_button = $(e.target).closest('.like-dislike-buttons__button'),
			button_share = $(clicked_button).siblings().children('.like-dislike-buttons__share');
			button_text = $(clicked_button).siblings().children('span');
			
			if (0 == $(e.target).parents('.like-dislike-buttons__share').length) {
				button_share.toggle();
				button_text.toggle();
			}
		});
	}
	
	social_toggle.click(function(e) {
		e.preventDefault();
		close_search(e);
		$(e.target).closest('.social-share').toggleClass('sub-menu--is-open');
	});
	
	search_toggle.click(function(e) {
		e.preventDefault();
		close_social_icons(e);
		$(e.target).closest('.social-share').toggleClass('search--is-open');
		$(search_input).focus();
	});
	
	function close_search(e) {
		$(e.target).closest('.social-share').removeClass('search--is-open');
	}
	
	function close_social_icons(e) {
		$(e.target).closest('.social-share').removeClass('sub-menu--is-open');
	}

	function move_like_dislike(source, destination) {
		source.insertBefore(destination);
	}

	// sharing stuff
	
	socialToggle();
	
	function socialToggle() {
		
		var social_share_link = $('.social-share__social-list-item a, .social-share__social-sub-menu-item a');
		
		$(social_share_link).click( function(e) {
			
			if ( ! $(e.target).hasClass('dont-prevent') ) {
				e.preventDefault();
			}
			
			var share_location = 'Share';
			var data_share_location = $( this ).data( 'share-location' );
			if ( undefined !== data_share_location && data_share_location.length ) {
				share_location = data_share_location;
			}
			
			var shareMethod = $( this ).data( 'social' );
			if ( shareMethod == 'facebook' ) {
				loadFacebookShare( $( this ).data( 'url' ), $( this ).data( 'redirect' ), $( this ).data( 'title' ), share_location );
			} else if ( shareMethod == 'twitter' ) {
				loadTwitterShare( $( this ).data( 'url' ), $( this ).data( 'text' ), $( this ).data( 'title' ), share_location );
			}
			
		});
	}
	
	function loadFacebookShare( url, redirect, title, location_label ) {
		if (typeof(location_label)==='undefined') location_label = 'Share';
		var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
		var URL = "https://www.facebook.com/dialog/share?" +
		"app_id=5570420003063364" +
		"&display=popup" +
		"&href=" + url +
		"&redirect_uri=" + redirect;
		return window.open(URL, "_blank", strWindowFeatures);
	}
	
	function loadTwitterShare( url, text, title, location_label ) {
		if (typeof(location_label)==='undefined') location_label = 'Share';
		var strWindowFeatures = "location=yes,height=400,width=685,scrollbars=yes,status=yes";
		var URL = "https://twitter.com/intent/tweet?" +
		"&text=" + text +
		"&url=" + url;
		return window.open(URL, "_blank", strWindowFeatures);
	}
	
})( jQuery );
