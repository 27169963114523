(function( $ ) {
	var
	header = $('header'),
	articles = $('article');
	article_first = articles[0],
	article_second = articles[1],
	paragraph_first = $(article_first).find('.entry-content > p')[0],
	paragraph_second = $(article_second).find('.entry-content > p')[0],
	mobile_silder_first = $('#mobile-slider--first'),
	mobile_silder_second = $('#mobile-slider--second'),
	init_mobile_silder_first = mobile_silder_first.find('.mobile-slider__slider'),
	init_mobile_silder_second = mobile_silder_second.find('.mobile-slider__slider');
	
	if (header.hasClass('site-header--is-single')) {
		
		move_slider(mobile_silder_first, paragraph_first);
		move_slider(mobile_silder_second, paragraph_second);
		init_mobile_slider(init_mobile_silder_first);
		init_mobile_slider(init_mobile_silder_second);
	}
	
	wrap_image_block_inner();
	
	function move_slider(source, destination) {
		source.insertAfter(destination);
	}
	
	function init_mobile_slider(slider) {
		slider.slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
		});
	}
	
	function wrap_image_block_inner() {
		var image_block = $('.wp-block-image');
		image_block.each(function() {
			if ( ! $(this).hasClass('wp-block-image--has-credit') ) {
				$(this).children().wrapAll(`<div class="wp-block-image__inner"></div>`);
			}
		});
	}
	
	if ($('#content-nav').length) {
		var elem = new Foundation.SmoothScroll($('#content-nav'));
	}

})( jQuery );
