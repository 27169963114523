(function($){

		if ( $('#header-slider').length ) {
			var header_carousel = $( '.header-carousel__slider' );
			
			header_carousel.slick({
				infinite: true,
				slidesToShow: 5,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1440,
						settings: {
							slidesToShow: 5,
						}
					},
					{
						breakpoint: 1366,
						settings: {
							slidesToShow: 4,
						}
					},
					{
						breakpoint: 992,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
						}
					},
				]
			});
		} 

})(jQuery);