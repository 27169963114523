(function( $ ) {

    	/**
	 * Add load more posts button to Category archive pages
	 */
	let currentPage = 1;
    let ajaxUrl = '../../../../wp-admin/admin-ajax.php'
	$('#load-more-cats').on('click', function() {
		currentPage++;
		$.ajax({
			type: 'POST',
			url: ajaxUrl,
			dataType: 'html',
			data: {
			  action: 'category_load_more',
			  paged: currentPage,
			},
			success: function (res) {
			  $('.category-section__posts').append(res);
			}
		  });
	});

})( jQuery );