jQuery(document).ready( function($) {
	var toggleButton = $('.toggle-content');
	
	//toggleButton
	toggleButton.on('click', function() {
		var target = $( this ).data( 'content' );
		
		//added class for cover
		$( '.targets_list' ).toggleClass('target-cover');
		$( this ).toggleClass('is-active');
		
		//override the normal toggle js
		if( $( '.targets_list' ).hasClass('target-cover') ){
			$( this ).html('Toggle Content');
		}else {
			$( this ).html('Toggle Content');
		}
	});
})